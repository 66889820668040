import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
})
export class SideBarComponent implements OnInit, OnDestroy {
  currentUserSubcription: any;
  // currentUserInfo!: User;
  isSupportUser: boolean = false;
  menus: any = [];
  businessData: any;
  activeMenuIndex: number = 0;
  currentPagePath = '/';
  logoUrl: any;
  tempLogin = sessionStorage.getItem('login');
  userId: any = sessionStorage.getItem('UserID');
  constructor(
    private currentUserService: CurrentUserService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.currentUserSubcription.unsubscribe();
  }

  ngOnInit(): void {}

  loadSentSmsEmailInCurrentMonth() {}

  getSideBar() {}

  sortMenu(menu: any) {
    if (menu.menuType == 'Group') {
      menu.items.sort((a: any, b: any) => {
        return a.position - b.position;
      });
    }

    if (menu.items != null && menu.items.length > 0) {
      for (let i = 0; i < menu.items.length; i++) {
        this.sortMenu(menu.items[i]);
      }
    }
  }

  loadBusinessData() {}

  setUpdatedBuisnessInfo(data: any) {
    this.loadBusinessData();
  }



  //--------------------aparna-----------------
  upload() {}
  download() {}
  amcDetail() {}
  uploadIndirectExpenses() {}
  generiDSR() {}
}
