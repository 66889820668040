import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-deletewarning',
  templateUrl: './deletewarning.component.html',
  styleUrls: ['./deletewarning.component.css'],
})
export class DeletewarningComponent {
  @Input() showModal: boolean = false;
  @Input() modalData: any;
  @Input() modalMessage: any;
  @Output() modalClosed = new EventEmitter<any>();
  constructor() {}

  hideModal() {
    this.modalClosed.emit({ close: true, isDelete: false });
    this.showModal = false;
  }

  delete() {
    this.modalClosed.emit({ close: true, isDelete: true });
    this.showModal = false;
  }
}
