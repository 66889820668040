import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  HeaderComponent,
  FormatTimePipe,
} from './reusableComponents/header/header.component';
import { DialogModule } from 'primeng/dialog';
import { DeletewarningComponent } from './reusableComponents/deletewarning/deletewarning.component';
import { BreadcrumbComponent } from './reusableComponents/header/breadcumb/breadcrumb.component';
import { ErrorStylingDirectiveDirective } from './directives/error-styling-directive.directive';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ImportFileComponent } from './reusableComponents/import-file/import-file.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { ApiService } from '../services/api.service';
import { LocalStorageService } from '../services/local-storage.service';
import { AuthErrorComponent } from '../modules/loginPage/component/auth-error/auth-error.component';
import { ForgetPasswordComponent } from '../modules/loginPage/component/forget-password/forget-password.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    DeletewarningComponent,
    HeaderComponent,
    FormatTimePipe,
    ErrorStylingDirectiveDirective,
    ImportFileComponent,
    SideBarComponent,
    AuthErrorComponent,
    ForgetPasswordComponent,
  ],
  imports: [
    CommonModule,
    NgxUiLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DialogModule,
    BadgeModule,
    ButtonModule,
    ToastModule,
    TooltipModule,
  ],
  exports: [
    NgxUiLoaderModule,
    FormsModule,
    DeletewarningComponent,
    ReactiveFormsModule,
    HeaderComponent,
    SideBarComponent,
    BreadcrumbComponent,
    AutoCompleteModule,
    ImportFileComponent,
  ],
  providers: [ApiService, LocalStorageService],
})
export class SharedModule {}
