<main class="login login-page">
  <img src="assets/img/lpp.jpg" class="login-geometric-bg" />
  <div class="top-section">
    <div class="center-content">
      <img src="assets/img/logo.png" class="logo" />
      <!-- <ng-container *ngIf="!isChangePassword">
        <div class="main-box">
          <h2 class="title">Forgot Password</h2>
          <p class="small-text">
            Enter your email address and click on send request to receive the
            verification code.
          </p>
          <ng-container *ngIf="errMsg">
            <app-auth-error
              error="errMsg"
              success="accountCreationMessage"
            ></app-auth-error>
          </ng-container>

          <form
            [formGroup]="loginForm"
            (ngSubmit)="submitForm()"
            class="input-color"
          >
            <div
              class="input-field form-group"
              ngClass="
                loginForm.controls.['email'].invalid &&
                (loginForm.controls.['email'].dirty ||
                  loginForm.controls.['email']touched)
                  ? 'has-error'
                  : ''
              "
            >
              <span class="label">Email</span>
              <input
                formControlName="email"
                type="text"
                class="input form-control"
              />
              <div
                class="error error-msg"
                *ngIf="
                  (loginForm.dirty || loginForm.touched) &&
                  loginForm.controls['email'].errors?.['required']
                "
              >
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                Email is required.
              </div>
              <div
                class="error error-msg"
                *ngIf="
                  (loginForm.dirty || loginForm.touched) &&
                  loginForm.controls['email'].errors?.['pattern']
                "
              >
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                Email is invalid.
              </div>
            </div>

            <button [disabled]="!loginForm.valid" class="continue-btn">
              Continue
            </button>

            <p class="normal text-center no-account">
              <a routerLink="/" class="link">Sign In</a> |
              <a routerLink="/sign-up" class="link"> Sign up</a>
            </p>
          </form>
        </div>
      </ng-container> -->

      <!-- ----------------------------------------------------------------------- -->
      <!--                          Change PAsswrod Form                           -->
      <!-- ----------------------------------------------------------------------- -->
      <ng-container *ngIf="isChangePassword">
        <div class="main-box">
          <h2 class="title">Change Password</h2>
          <p class="small-text">
            Enter your password and confirmed password and submit to Change the
            password.
          </p>
          <ng-container *ngIf="errMsg">
            <app-auth-error
              error="errMsg"
              success="accountCreationMessage"
            ></app-auth-error>
          </ng-container>

          <!-- ----------------------------------------------------------------------- -->
          <!--                          Forget Password FOrm                           -->
          <!-- ----------------------------------------------------------------------- -->
          <form
            [formGroup]="changePasswordForm"
            (ngSubmit)="submitChangePassword()"
            class="input-color"
          >
            <div
              class="form-group"
              ngClass="
                f['userName'].invalid &&
                (f['userName'].dirty || f['userName'].touched)
                  ? 'has-error'
                  : ''
              "
            >
              <label>UserID<span class="error-red">*</span></label>
              <input
                type="text"
                class="form-control input-type2"
                formControlName="userName"
                appErrorStylingDirective
                readonly
              />
              <div class="error error-msg">
                <div *ngIf="f['userName'].errors?.['required']">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  User Name is required.
                </div>
              </div>
            </div>
            <!-- ----------------------------- Email Field ----------------------------- -->
            <div
              class="form-group"
              ngClass="
                f['email'].invalid && (f['email'].dirty || f['email'].touched)
                  ? 'has-error'
                  : ''
              "
            >
              <label>Email<span class="error-red">*</span></label>
              <input
                type="text"
                class="form-control input-type2"
                formControlName="email"
                readonly
                appErrorStylingDirective
              />
              <div class="error error-msg">
                <div *ngIf="f['email'].errors?.['required']">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  Email is required.
                </div>
                <div *ngIf="f['email'].errors?.['pattern']">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  Email is invalid.
                </div>
              </div>
            </div>

            <!-- --------------------------- Password Field ---------------------------- -->
            <div
              class="form-group"
              [ngClass]="
                f['password'].invalid &&
                (f['password'].dirty || f['password'].touched)
                  ? 'has-error'
                  : ''
              "
            >
              <label>Password<span class="error-red">*</span></label>
              <input
                type="password"
                autocomplete="new-password"
                class="form-control input-type2"
                formControlName="password"
                appErrorStylingDirective
              />
              <div class="error error-msg">
                <div *ngIf="f['password'].errors?.['required']">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  Password is required.
                </div>
                <div *ngIf="f['password'].errors?.['pattern']">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  Password must contain one small character, one upper case
                  charcter, one number and one of (!, @, $). It must be minimum
                  8 characters long.
                </div>
              </div>
            </div>

            <!-- --------------------------- Password Field ---------------------------- -->
            <div
              class="form-group"
              [ngClass]="
                f['confirmPassword'].invalid &&
                (f['confirmPassword'].dirty || f['confirmPassword'].touched)
                  ? 'has-error'
                  : ''
              "
            >
              <label>Confirm Password<span class="error-red">*</span></label>
              <input
                type="password"
                class="form-control input-type2"
                formControlName="confirmPassword"
                appErrorStylingDirective
              />
              <div class="error error-msg">
                <div *ngIf="f['confirmPassword'].errors?.['required']">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  Confirm Password is required.
                </div>
                <div *ngIf="f['confirmPassword'].errors?.['pattern']">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  Confirm Password is invalid.
                </div>
              </div>
            </div>

            <!-- --------------------------- Password Field ---------------------------- -->
            <!-- <div
              class="form-group"
              ngClass="
                f['confirmationCode'].invalid &&
                (f['confirmationCode'].dirty || f['confirmationCode'].touched)
                  ? 'has-error'
                  : ''
              "
            >
              <label>Confirmation Code<span class="error-red">*</span></label>
              <input
                type="text"
                class="form-control input-type2"
                formControlName="confirmationCode"
                appErrorStylingDirective
              />
              <div class="error error-msg">
                <div *ngIf="f['confirmationCode'].errors?.['required']">
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  Confirm Code is required.
                </div>
              </div>
            </div> -->

            <button [disabled]="!changePasswordForm.valid" class="continue-btn">
              Continue
            </button>

            <p class="normal text-center no-account">
              <a routerLink="/" class="link">Sign In</a> |
              <a routerLink="/sign-up" class="link"> Sign up</a>
            </p>
          </form>
        </div>
      </ng-container>
    </div>
  </div>
</main>
