export const environment = {
  production: false,
  apiUrl: 'http://localhost:51326/',
  // apiUrl: 'http://192.168.2.244:8099/',
  // apiUrl: 'http://localhost:51326/',
  // production: true,
  // redirect: 'http://192.168.1.33:8004/',
  // uploadURL: 'http://localhost:4200/upload',
  // uniqueIDUrl:
  //   'http://192.168.1.33:8004/LandningPage?uniqueID=C2D65FD2-17BF-473B-8C18-413247B40B1F',

  //apiUrl: 'https://172.21.101.5:8087',
};
