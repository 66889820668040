<!-- <div id="div1" *ngIf="loginFormDiv">
  <main class="login login-page">
   <div class="top-section">
      <div class="center-content">
        <div class="main-box">
          <img src="assets/img/shortLogo.png" class="logo" />
          <h2 class="title">Sign in to your account</h2>
          <ng-container *ngIf="errMsg">
              </ng-container>

          <form
            [formGroup]="loginForm"
            (ngSubmit)="submitForm()"
            class="input-color"
          >
            <div class="input-field form-group">
              <span class="label">User Name</span>
              <input
                type="text"
                class="input form-control"
                id="username"
                name="username"
              />
             
            </div>
            <div class="input-field form-group">
              <div class="password-row">
                <p class="label">Password</p>
                <a class="label forgot" routerLink="/forget-password"
                  >Forgot your password?</a
                >
              </div>
              <div class="password-wrapper form-group">
                <input
                  formControlName="password"
                  [type]="!isShowPassword ? 'password' : 'text'"
                  name="password"
                  class="input form-control"
                  id="password"
                />
                <span
                  class="show-hide graymedium"
                  (click)="toggleShowPasswordField()"
                >
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-eye-slash': !isShowPassword,
                      'fa-eye': isShowPassword
                    }"
                  ></i>
                </span>
              </div>
            </div>
            <button class="continue-btn" (click)="getLoginAuth()">
              Continue
            </button>
            <p class="normal text-center no-account">
              Don’t have an account?
              <a routerLink="/sign-up" class="link"> Sign up</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </main>
</div>
<div id="div2" *ngIf="companyFormDiv">
  <main class="login login-page">
    <div class="top-section">
      <div class="center-content">
        <div class="main-box">
          <img src="assets/img/shortLogo.png" class="logo" />
          <h2 class="title">Company Selection</h2>

          <form [formGroup]="loginForm" class="input-color">
            <div class="input-field form-group">
              <span class="label">Company Name</span>
              <select
                class="input form-control"
                type="text"
                id="companyName"
                name="companyName"
                (change)="getLocationByData()"
              >
                <option default value=""></option>
                <option
                  *ngFor="let name of companyData"
                  [value]="name.CompanyID"
                >
                  {{ name.CompanyName }}
                </option>
              </select>
            </div>
            <div class="input-field form-group">
              <span class="label">Branch</span>
              <select
                class="input form-control"
                type="text"
                id="branch"
                name="branch"
              >
                <option default value=""></option>
                <option
                  *ngFor="let name of getLocation"
                  [value]="name.LocationID"
                >
                  {{ name.LocationName }}
                </option>
              </select>
            </div>

            <button class="continue-btn" (click)="goToUpload()">
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  </main>
</div> -->

<div id="div1" *ngIf="loginFormDiv">
  <main class="login login-page">
    <!-- <img style="margin-top: -20px" src="assets/img/bg/red11.jpg" class="bcg" /> -->
    <div class="top-section">
      <div class="center-content">
        <div class="main-box">
          <img src="assets/img/shortLogo.png" class="logo" />
          <h2 class="title">Sign in to your account</h2>
          <ng-container *ngIf="errMsg">
            <!-- <app-auth-error [error]="errMsg" [success]="accountCreationMessage"></app-auth-error> -->
          </ng-container>

          <form
            [formGroup]="loginForm"
            (ngSubmit)="submitForm()"
            class="input-color"
          >
            <div class="input-field form-group">
              <span class="label">User Name</span>
              <input
                type="text"
                class="input form-control"
                id="username"
                name="username"
              />
              <!-- <div
              class="error error-msg"
              *ngIf="
                (loginForm.dirty || loginForm.touched) &&
                loginForm.controls['email'].errors?.['required']
              "
            >
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              User name is required.
            </div> -->
              <!-- <div
              class="error error-msg"
              *ngIf="
                (loginForm.dirty || loginForm.touched) &&
                loginForm.controls['email'].errors?.['pattern']
              "
            >
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              Email is invalid.
            </div> -->
            </div>
            <div class="input-field form-group">
              <!-- [ngClass]="
              loginForm.controls['password'].invalid &&
              (loginForm.controls['password'].dirty ||
                loginForm.controls['password'].touched)
                ? 'has-error'
                : ''
            " -->
              <div class="password-row">
                <p class="label">Password</p>
                <a class="label forgot" (click)="RedirectToResetPassword()"
                  >Forgot your password?</a
                >
              </div>
              <div class="password-wrapper form-group">
                <input
                  formControlName="password"
                  [type]="!isShowPassword ? 'password' : 'text'"
                  name="password"
                  class="input form-control"
                  id="password"
                />
                <span
                  class="show-hide graymedium"
                  (click)="toggleShowPasswordField()"
                >
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-eye-slash': !isShowPassword,
                      'fa-eye': isShowPassword
                    }"
                  ></i>
                </span>
              </div>
              <!-- <div
              class="error error-msg"
              *ngIf="
                (loginForm.dirty || loginForm.touched) &&
                loginForm.controls['password'].errors?.['required']
              "
            >
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              Password is required.
            </div>-->
            </div>
            <!-- [disabled]="loginForm.invalid" -->
            <button class="continue-btn" (click)="getLoginAuth()">
              Continue
            </button>
            <p class="normal text-center no-account">
              Don’t have an account?
              <a routerLink="/sign-up" class="link"> Sign up</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </main>
</div>
<div id="div2" *ngIf="companyFormDiv">
  <main class="login login-page">
    <!-- <img style="margin-top: -20px" src="assets/img/bg/red11.jpg" class="bcg" /> -->
    <div class="top-section">
      <div class="center-content">
        <div class="main-box">
          <img src="assets/img/shortLogo.png" class="logo" />
          <h2 class="title">Company Selection</h2>

          <form [formGroup]="loginForm" class="input-color">
            <div class="input-field form-group">
              <span class="label">Company Name</span>
              <p-dropdown
                [options]="companyData"
                placeholder="Select Company ..."
                #comp
                class="CompanyID"
                formControlName="CompanyID"
                name="CompanyID"
                id="CompanyID"
                optionLabel="CompanyName"
                optionValue="CompanyID"
                [style]="{ minWidth: '100%', height: '38px' }"
                (onChange)="getLocationByData($event, comp)"
              >
              </p-dropdown>
            </div>
            <div class="input-field form-group">
              <span class="label">Branch</span>
              <p-dropdown
                [options]="getLocation"
                placeholder="Select Location ..."
                #branch
                id="branch"
                name="branch"
                optionLabel="LocationName"
                optionValue="LocationID"
                [style]="{ minWidth: '100%', height: '38px' }"
                (onChange)="selectBranch($event, branch)"
              >
              </p-dropdown>
            </div>
            <div class="input-field form-group">
              <span class="label">WorkPeriod</span>
              <p-dropdown
                [options]="getWorkPeriod"
                placeholder="Select WorkPeriod..."
                #WorkPeriod
                id="WorkPeriod"
                name="WorkPeriod"
                optionLabel="Remarks"
                optionValue="WrkBlkID"
                [style]="{ minWidth: '100%', height: '38px' }"
                (onChange)="selectWorkPeriod($event, WorkPeriod)"
                appendTo="body"
              >
              </p-dropdown>
            </div>

            <button class="continue-btn" (click)="goToUpload()">
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  </main>
</div>
