<div class="side-bar accordion" id="sidebar-accordian">
  <div class="side-bar-header">
    <img id="logoHeader" src="assets/img/logo.png" alt="" />
  </div>
  <div class="divider"></div>

  <div class="business-container">
    <div class="centered-img">
      <img
        [src]="logoUrl || 'assets/img/profilePicture.jpg'"
        alt="profile img"
        class="profile-img"
      />
    </div>

    <div class="business-content">
      <span>Welcome, {{ userId }}</span>
    </div>
  </div>
  <div class="divider"></div>
  <ng-container> </ng-container>

  <div class="side-bar-body">
    <div class="item" *ngFor="let menu of menus; let i = index">
      <div class="item-details">
        <div class="item-icon">
          <i class="{{ menu.icon }}"></i>
        </div>
        <ng-container *ngIf="menu?.items.length > 0">
          <a
            class="item-name collapsed"
            [ngClass]="activeMenuIndex === i ? 'active' : ''"
            data-bs-toggle="collapse"
            data-toggle="collapse"
            role="button"
            aria-expanded="true"
            [attr.aria-controls]="'menu-' + menu.id"
            [attr.data-bs-target]="'#menu-' + menu.id"
            >{{ menu?.displayName }}</a
          >
          <span
            class="icon-right"
            [ngClass]="activeMenuIndex === i ? 'active' : ''"
            data-bs-toggle="collapse"
            data-toggle="collapse"
            aria-expanded="true"
            [attr.aria-controls]="'menu-' + menu.id"
            [attr.data-bs-target]="'#menu-' + menu.id"
          >
            <i class="fa fa-chevron-down"></i>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
    <!-- 
    <div class="item">
      <div class="upload item-details">
        <div class="item-icon">
          <i class="fa fa-upload"></i>
        </div>
        <a class="item-name" href="landingPage" (click)="generiDSR()">
          landingPage
        </a>
      </div>
      <div class="logout item-details">
        <div class="item-icon">
          <i class="fa fa-power-off"></i>
        </div>
        <a class="item-name" href="javascript:void(0)" (click)="logout()">
          Logout
        </a>
      </div> 
    </div>
  </div>
  <div class="side-bar-footer">
    <p>Contact Support - Privacy & Terms</p>
  </div>
  -->
