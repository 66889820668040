import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Login, Location, UserRoleModel } from '../../models/login.model';
import { environment } from 'src/environments/environment.prod';
//import { Login, Location } from '../../models/login.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private baseUrl = 'api/LPInternAPI';

  constructor(private http: HttpClient) {}

  login(loginModel: Login): Observable<Object> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Origin', 'https://localhost:4200/')
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Host', 'https://172.21.101.5:8084');

    return this.http.post(
      `${environment.apiUrl}${this.baseUrl}/LoginAuthonticate`,
      loginModel
    );
  }

  getLocation(toGetLocation: Location): Observable<Object> {
    return this.http.post(
      `${environment.apiUrl}${this.baseUrl}/GetLocationByCompanyId`,
      toGetLocation
    );
  }

  getUserRole(userRoleModel: UserRoleModel): Observable<Object> {
    return this.http.post(
      `${environment.apiUrl}${this.baseUrl}/GetUserRole`,
      userRoleModel
    );
  }
  GrpMenuList(AppID: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('AppID', AppID);
    return this.http.get(`${environment.apiUrl}${this.baseUrl}/GetGrpMenu`, {
      params: queryParams,
    });
  }

  GetWorkPeriodID(CompanyID: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('CompanyID', CompanyID);
    return this.http.get(
      `${environment.apiUrl}${this.baseUrl}/GetWorkPeriodID`,
      {
        params: queryParams,
      }
    );
  }

  fetchUserData(UserID: string): Observable<Object> {
    return this.http.get(
      `${environment.apiUrl}${this.baseUrl}/FetchUserData/` + UserID
    );
  }

  fetchforgotPasswordData(id: string): Observable<Object> {
    return this.http.get(
      `${environment.apiUrl}${this.baseUrl}/ForgotPasswordData/` + id
    );
  }

  resetPassword(userParameter: any): Observable<Object> {
    return this.http.post(
      `${environment.apiUrl}${this.baseUrl}/ResetPassword`,
      userParameter
    );
  }
}
