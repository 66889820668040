export class Login {
  Password: any;
  UserName: any;
}

export class Location {
  CompanyID: any;
  UserName: any;
}

export class UserRoleModel {
  UserName: any;
  AppID: any;
}
