import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/loginPage/component/login-page/login.component';
import { ForgetPasswordComponent } from './modules/loginPage/component/forget-password/forget-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'landingPage', pathMatch: 'full' },
  {
    path: 'landingPage',
    loadChildren: () =>
      import('./modules/landingPage/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
    data: {
      breadcrumb: 'LandingPage',
    },
  },
  { path: 'login', component: LoginComponent },
  {
    path: 'forget-password/:UserID',
    component: ForgetPasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
