import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RegexEnum } from 'src/app/shared/common/constants/regex';
import { CloseScrollStrategy } from '@angular/cdk/overlay';
//import { Login, Location } from '../../models/login.model';
import { LoginService } from '../service/login.service';
import { Login, Location, UserRoleModel } from '../../models/login.model';
// import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
// import { AuthService } from '../../services/auth.service';
// import { currentUserService } from '../../services/current-user.service';

// @Component({
//   selector: 'app-login',
//   templateUrl: './login.component.html',
//   styleUrls: ['./login.component.css'],
// })
// export class LoginComponent implements OnInit {
//   loginForm!: FormGroup;
//   businessId: any;
//   isShowPassword: any;
//   errMsg = '';
//   accountCreationMessage: any;
//   loginParameter: Login = new Login();
//   locationParameter: Location = new Location();
//   loginFormDiv: boolean = true;
//   companyFormDiv: boolean = false;
//   companyData: any = [];
//   locationData: any = [];
//   companyValue: any = [];
//   getLocation: any = [];
//   CompanyIDData: any;
//   companyName: any;
//   locationID: any;
//   locationName: any;
//   username: any;
//   userFullName: any = '';

//   constructor(
//     private formBuilder: FormBuilder,
//     private loginService: LoginService,
//     private router: Router
//   ) {}

//   ngOnInit(): void {
//     this.loginForm = this.formBuilder.group({
//       username: ['', [Validators.required]],
//       password: ['', [Validators.required]],
//     });

//     // if (this.username && this.CompanyIDData != '') {
//     //   this.getLoginAuth();
//     // }
//   }
//   // this.getLocationByData();
//   // this.accountCreationMessage = history.state.isDataModified ?? null;
//   // this.currentUserService.currentUserSubject.subscribe((data) => {
//   //   this.localStorageService.storeItem('currentUser', data);
//   //   if (data && data?.businessId) {
//   //     this.router.navigate(['/users/profile']);
//   //   }
//   // });

//   // setLoginAuth() {
//   //   this.uploadFile = {
//   //     DocumentName: docName,
//   //     TypeId: typeValue,
//   //   };
//   // }

//   async submitForm() {
//     this.accountCreationMessage = '';
//     if (this.loginForm.invalid) {
//       return;
//     }
//   }

//   //   from(this.authService.getUserByEmail(this.loginForm.value.email))
//   //     .pipe(
//   //       switchMap((userData: any): any => {
//   //         if (userData) {
//   //           this.businessId = userData.tenantId;
//   //         } else {
//   //           this.errMsg = 'Record for given Email Id is not found';
//   //           return this.apiService.error(
//   //             'Record for given Email Id is not found. Please contact our support team for further assistance.'
//   //           );
//   //         }
//   //         if (this.businessId) {
//   //           return from(this.authService.getBusinessData(this.businessId));
//   //         }
//   //         this.errMsg =
//   //           'There is no business record found for ' +
//   //           this.loginForm.value.email;
//   //         return this.apiService.error(
//   //           'There is no business record found for ' +
//   //             this.loginForm.value.email +
//   //             ' Please contact our support team for further assistance.'
//   //         );
//   //       })
//   //     )
//   //     .subscribe((businessData: any) => {
//   //       if (!businessData.deleted) {
//   //         this.authService.emitBuisnessInfoChange(businessData);
//   //         this.localStorageService.storeItem('businessInfo', businessData);
//   //         this.userLoginAfterBusinessVerification(this.loginForm.value);
//   //       }
//   //     });
//   // }

//   // userLoginAfterBusinessVerification(loginCredentials: any) {
//   //   this.authService
//   //     .loginByEmailAndPass(loginCredentials)
//   //     .then((data: any) => {
//   //       this.localStorageService.storeItem('currentUser', data);
//   //       this.currentUserService.currentUserSubject.next(data);
//   //       this.authService.currentUserSubject.next(data);
//   //       this.redirect(data.roles);
//   //       this.errMsg = '';
//   //     })
//   //     .catch((e) => {
//   //       if (e.error.message.trim() == 'Email is not verified.'.trim()) {
//   //         this.apiService.error(
//   //           'Verification pending - Please verify your email'
//   //         );
//   //         this.errMsg =
//   //           'Verification pending - Look for the verification email in your inbox and click the link in that email. A confirmation message will appear in your web browser';
//   //       } else {
//   //         this.apiService.error(
//   //           'The email or password is incorrect. Please check and try again.'
//   //         );
//   //         this.errMsg =
//   //           'The email or password is incorrect. Please check and try again.';
//   //       }
//   //     });
//   // }

//   // redirect(roles: any) {
//   //   if (roles.indexOf('Patient') > -1) {
//   //     this.router.navigate(['/patient-portal/patient/dashboard']);
//   //   } else {
//   //     //  this.router.navigate(['/appointment/calendar']);
//   //     this.router.navigate(['/users/profile']);
//   //   }
//   // }

//   toggleShowPasswordField() {
//     this.isShowPassword = !this.isShowPassword;
//   }

//   getLoginAuth() {
//     this.username = (<HTMLInputElement>(
//       document.getElementById('username')
//     )).value;
//     let password = (<HTMLInputElement>document.getElementById('password'))
//       .value;
//     sessionStorage.setItem('password', password);
//     this.loginParameter = {
//       UserName: this.username,
//       Password: password,
//     };

//     this.loginService.login(this.loginParameter).subscribe(
//       (data: any) => {
//         this.companyData = data.Data[1];
//         if (data.Data.length == 2) {
//           this.loginFormDiv = false;
//           this.companyFormDiv = true;

//           //   this.localStorageService.storeItem('currentUser', data);
//           // this.currentUserService.currentUserSubject.next(data);
//           // this.authService.currentUserSubject.next(data);
//         } else {
//           window.alert('Incorrect Username & Password');
//         }
//       },
//       (error) => {}
//     );
//   }

//   getLocationByData() {
//     var CompanyID = <HTMLSelectElement[]>(
//       (<any>document.getElementsByName('companyName'))
//     );

//     for (let i = 0; i < CompanyID.length; i++) {
//       this.CompanyIDData = CompanyID[i].selectedOptions[0].value;
//       this.companyName = CompanyID[i].selectedOptions[0].text;
//       this.locationParameter = {
//         UserName: this.username,
//         CompanyID: this.CompanyIDData,
//       };

//       this.loginService.getLocation(this.locationParameter).subscribe(
//         (data: any) => {
//           this.getLocation = data.Data;
//           // for (let i = 0; i < this.getLocation.length; i++) {
//           //   this.locationData = this.getLocation[i];
//           // }
//         },
//         (error) => {}
//       );
//     }
//   }

//   goToUpload() {
//     let companyID = (<HTMLInputElement>document.getElementById('companyName'))
//       .value;
//     let branchID = (<HTMLInputElement>document.getElementById('branch')).value;

//     var locationId = <HTMLSelectElement[]>(
//       (<any>document.getElementsByName('branch'))
//     );
//     for (let i = 0; i < locationId.length; i++) {
//       this.locationID = locationId[i].selectedOptions[0].value;
//       this.locationName = locationId[i].selectedOptions[0].text;
//     }

//     let DefaultProfileImg = 'assets/img/profile.jpg';
//     if (companyID != '' && branchID != '') {
//       sessionStorage.setItem('login', 'true');

//       sessionStorage.setItem('UserID', this.username);
//       sessionStorage.setItem('UserName', this.userFullName);
//       sessionStorage.setItem('CompanyID', this.CompanyIDData);
//       sessionStorage.setItem('CompanyName', this.companyName);
//       sessionStorage.setItem('LocationId', this.locationID);
//       sessionStorage.setItem('LocationName', this.locationName);
//       sessionStorage.setItem('WorkPeriodID', '81');
//       sessionStorage.setItem('ProfilePhoto', DefaultProfileImg);

//       this.router.navigate(['/landingPage']).then(() => {
//         window.location.reload();
//       });
//     } else {
//       window.alert('Please Select Company Name & Branch');
//     }
//   }
// }

import { Dropdown } from 'primeng/dropdown';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ToasTMessageService } from 'src/app/services/toast-message.service';
// import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
// import { AuthService } from '../../services/auth.service';
// import { currentUserService } from '../../services/current-user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  businessId: any;
  isShowPassword: any;
  errMsg = '';
  accountCreationMessage: any;
  loginParameter: Login = new Login();
  locationParameter: Location = new Location();
  loginFormDiv: boolean = true;
  companyFormDiv: boolean = false;
  companyData: any = [];
  locationData: any = [];
  companyValue: any = [];
  getLocation: any = [];
  getWorkPeriod: any = [];
  CompanyIDData: any;
  companyName: any;
  locationID: any;
  locationName: any;
  workPeriodID: any;
  username: any;
  userFullName: any = '';
  filteredBranchIs: any[];

  userRoleModel: UserRoleModel = new UserRoleModel();
  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toastService: ToasTMessageService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      CompanyID: [''],
      branch: [''],
      WorkPeriod: [''],
    });
  }

  async submitForm() {
    this.accountCreationMessage = '';
    if (this.loginForm.invalid) {
      return;
    }
  }

  //   from(this.authService.getUserByEmail(this.loginForm.value.email))
  //     .pipe(
  //       switchMap((userData: any): any => {
  //         if (userData) {
  //           this.businessId = userData.tenantId;
  //         } else {
  //           this.errMsg = 'Record for given Email Id is not found';
  //           return this.apiService.error(
  //             'Record for given Email Id is not found. Please contact our support team for further assistance.'
  //           );
  //         }
  //         if (this.businessId) {
  //           return from(this.authService.getBusinessData(this.businessId));
  //         }
  //         this.errMsg =
  //           'There is no business record found for ' +
  //           this.loginForm.value.email;
  //         return this.apiService.error(
  //           'There is no business record found for ' +
  //             this.loginForm.value.email +
  //             ' Please contact our support team for further assistance.'
  //         );
  //       })
  //     )
  //     .subscribe((businessData: any) => {
  //       if (!businessData.deleted) {
  //         this.authService.emitBuisnessInfoChange(businessData);
  //         this.localStorageService.storeItem('businessInfo', businessData);
  //         this.userLoginAfterBusinessVerification(this.loginForm.value);
  //       }
  //     });
  // }

  // userLoginAfterBusinessVerification(loginCredentials: any) {
  //   this.authService
  //     .loginByEmailAndPass(loginCredentials)
  //     .then((data: any) => {
  //       this.localStorageService.storeItem('currentUser', data);
  //       this.currentUserService.currentUserSubject.next(data);
  //       this.authService.currentUserSubject.next(data);
  //       this.redirect(data.roles);
  //       this.errMsg = '';
  //     })
  //     .catch((e) => {
  //       if (e.error.message.trim() == 'Email is not verified.'.trim()) {
  //         this.apiService.error(
  //           'Verification pending - Please verify your email'
  //         );
  //         this.errMsg =
  //           'Verification pending - Look for the verification email in your inbox and click the link in that email. A confirmation message will appear in your web browser';
  //       } else {
  //         this.apiService.error(
  //           'The email or password is incorrect. Please check and try again.'
  //         );
  //         this.errMsg =
  //           'The email or password is incorrect. Please check and try again.';
  //       }
  //     });
  // }

  // redirect(roles: any) {
  //   if (roles.indexOf('Patient') > -1) {
  //     this.router.navigate(['/patient-portal/patient/dashboard']);
  //   } else {
  //     //  this.router.navigate(['/appointment/calendar']);
  //     this.router.navigate(['/users/profile']);
  //   }
  // }

  toggleShowPasswordField() {
    this.isShowPassword = !this.isShowPassword;
  }

  getLoginAuth() {
    this.username = (<HTMLInputElement>(
      document.getElementById('username')
    )).value;
    let password = (<HTMLInputElement>document.getElementById('password'))
      .value;
    sessionStorage.setItem('password', password);
    this.loginParameter = {
      UserName: this.username,
      Password: password,
    };

    this.loginService.login(this.loginParameter).subscribe(
      (data: any) => {
        this.companyData = data.Data[1];
        if (data.Data.length == 2) {
          this.loginFormDiv = false;
          this.companyFormDiv = true;

          //---------------- Role based API calling -------------------
          // this.userRoleModel = {
          //   UserName: this.username,
          //   AppID: 'AGGR',
          // };
          // this.loginService.getUserRole(this.userRoleModel).subscribe(
          //   (data: any) => {
          //     console.log(data.Data.Table);
          //     this.localStorageService.storeItem('roleData', data.Data.Table);
          //   },
          //   (error) => {}
          // );
        } else {
          window.alert('Incorrect Username & Password');
        }
      },
      (error) => {}
    );
  }

  getLocationByData(event: any, comp: Dropdown) {
    this.companyName = comp.selectedOption.CompanyName;
    this.CompanyIDData = comp.selectedOption.CompanyID;
    this.locationParameter = {
      UserName: this.username,
      CompanyID: event.value,
    };

    this.loginService.getLocation(this.locationParameter).subscribe(
      (data: any) => {
        this.getLocation = data.Data;
      },
      (error) => {}
    );
    this.loginService.GetWorkPeriodID(event.value).subscribe(
      (data: any) => {
        this.getWorkPeriod = data.Data.Table;
      },
      (error) => {}
    );
  }
  selectBranch(event: any, branch: Dropdown) {
    this.locationID = branch.selectedOption.LocationID;
    this.locationName = branch.selectedOption.LocationName;
  }
  selectWorkPeriod(event: any, WorkPeriod: Dropdown) {
    this.workPeriodID = WorkPeriod.selectedOption.WrkBlkID;
  }

  goToUpload() {
    let DefaultProfileImg = 'assets/img/profile.jpg';
    if (
      this.companyName != undefined &&
      this.locationID != undefined &&
      this.workPeriodID != undefined
    ) {
      sessionStorage.setItem('login', 'true');

      sessionStorage.setItem('UserID', this.username.toUpperCase());
      sessionStorage.setItem('UserName', this.userFullName);
      sessionStorage.setItem('CompanyID', this.CompanyIDData);
      sessionStorage.setItem('CompanyName', this.companyName);
      sessionStorage.setItem('LocationId', this.locationID);
      sessionStorage.setItem('LocationName', this.locationName);
      sessionStorage.setItem('WorkPeriodID', this.workPeriodID);
      sessionStorage.setItem('ProfilePhoto', DefaultProfileImg);

      this.router.navigate(['/landingPage']).then(() => {
        window.location.reload();
      });
    } else {
      window.alert('Please Select all the fields...');
    }
  }

  RedirectToResetPassword() {
    this.username = (<HTMLInputElement>(
      document.getElementById('username')
    )).value;

    this.loginService.fetchUserData(this.username).subscribe((data: any) => {
      if (data.StatusDesc == 'SUCCESS') {
        this.toastService.success(
          'An email with instructions to reset your password is sent to your registered email!'
        );
        this.router.navigate(['/']);
      } else {
        this.toastService.error("The user with given name doesn't exist!");
      }
    });
  }
}
