import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToasTMessageService } from 'src/app/services/toast-message.service';

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.css'],
})
export class ImportFileComponent {
  @Input() showImportModal: boolean = false;
  @Input() modalImportData: any;
  @Input() modalImportMessage: any;
  @Output() modalImportClosed = new EventEmitter<any>();
  file: any = null;
  fileName: any;

  selectedFiles?: FileList;
  allTypeFiles: any = [];
  allfiles: any[] = [];
  filePath: any[] = [];

  constructor(private alertService: ToasTMessageService) {}

  hideModal(close?: any) {
    this.fileName = this.allTypeFiles;
    // for (let i = 0; i < this.allTypeFiles.length; i++) {
    // this.fileName = this.allTypeFiles[i].name;
    //   console.log(this.fileName);
    // }
    if (close === 'upload') {
      this.modalImportClosed.emit({ close: true, isImport: this.fileName });
    } else {
      this.fileName = null;
      this.modalImportClosed.emit({ close: true, isImport: this.fileName });
    }

    this.showImportModal = false;
  }

  // onFileChange(event: any) {
  //   this.file = event.target.files[0];
  //   const ext = this.file.name.split('.').pop();
  //   console.log('file', ext);
  //   if (ext === 'xlsx') {
  //     this.fileName = this.file.name;
  //   } else {
  //     this.alertService.error('Please Upload Excel Files only');
  //   }
  // }
  selectFiles(event: any): void {
    this.selectedFiles = event.target.files;
  }

  // getUploadedFilePath(idx: number, file: File): void {
  //   if (file) {
  //     this.uploadService.upload(file).subscribe(
  //       (data: any) => {
  //         this.filePath.push(data.Data);
  //         const image = {
  //           srNo: '',
  //           name: '',
  //           type: '',
  //           jobNo: '',
  //           remark: '',
  //           size: '',
  //           url: '',
  //           path: '',
  //         };
  //         this.allfiles.push(file);
  //         image.name = file.name;
  //         image.path = data.Data;
  //         this.allTypeFiles.push(image);
  //       },
  //       () => {}
  //     );
  //   }
  // }

  // getFilesName(): void {
  //   if (this.selectedFiles) {
  //     for (let i = 0; i < this.selectedFiles.length; i++) {
  //       this.getUploadedFilePath(i, this.selectedFiles[i]);
  //     }
  //   }
  //   this.selectedFiles = null;
  // }
}
