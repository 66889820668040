import { Component, OnInit } from '@angular/core';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  // title = 'uploadDownload';
  title = 'File Upload';
  userData!: any;
  currentUrl: any;
  navbar: boolean = false;
  sidebar: boolean = false;
  public location = '';
  tempLogin = sessionStorage.getItem('login');

  constructor(private router: Router, private bnIdle: BnNgIdleService) {
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     if (event.url === '/login') {
    //       this.hideElement = true;
    //     } else {
    //       this.hideElement = false;
    //     }
    //   }
    // });

    if (this.tempLogin === 'true') {
      this.sidebar = true;
      this.navbar = true;
    }
    // if (sessionStorage.length == 0) {
    //   this.sidebar = false;
    //   this.navbar = false;
    // }
  }
  // getURL2() {
  //   return this.router.url.includes('/download');
  // }

  ngOnInit(): void {
    let time = '1200';
    sessionStorage.setItem('Time', time);
    this.bnIdle
      .startWatching(parseInt(time))
      .subscribe((isTimeOut: boolean) => {
        if (isTimeOut) {
          // sessionStorage.remove('');
          sessionStorage.clear();
          if (this.tempLogin == '') {
            this.sidebar = false;
            this.navbar = false;
          }
          window.location.reload();

          this.router.navigate(['/login']);
          this.bnIdle.stopTimer();
          alert('Session Time Out please login again...');
        }
      });
  }
}
