import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegexEnum } from 'src/app/shared/common/constants/regex';
import { MustMatch } from '../../validators/must-match.validator';
import { ToasTMessageService } from 'src/app/services/toast-message.service';
import { LoginService } from '../service/login.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
})
export class ForgetPasswordComponent implements OnInit {
  loginForm!: FormGroup;
  changePasswordForm: FormGroup;
  businessId: any;
  isShowPassword: any;
  errMsg = '';
  accountCreationMessage: any;
  isChangePassword = false;

  submitted: boolean = false;
  UserID: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastService: ToasTMessageService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.isChangePassword = true;
    this.changePasswordForm = this.formBuilder.group(
      {
        userName: ['', []],
        email: ['', []],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!])[a-zA-Z0-9@$!]{8,}$'
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );

    this.UserID = this.activatedRoute.snapshot.paramMap.get('UserID');

    if (this.UserID != null) {
      const userID = this.UserID;
      const currentUrl = this.router.url;
      $(document).ready(() => {
        if (currentUrl.endsWith('/forget-password/' + userID)) {
          this.loginService
            .fetchforgotPasswordData(userID)
            .subscribe((data: any) => {
              if (data.StatusDesc == 'SUCCESS') {
                this.changePasswordForm.patchValue({
                  userName: data.Data.Table[0].UserID,
                  email: data.Data.Table[0].UserEmail,
                });
              } else {
                this.toastService.error('User ID not exist.');
              }
            });
        } else {
          alert('Invalid URL.');
        }
      });
    }
  }

  get f() {
    return this.changePasswordForm.controls;
  }

  submitChangePassword() {
    let userParameter = {
      UserID: this.changePasswordForm.value.userName,
      Password: this.changePasswordForm.value.password,
    };
    this.loginService.resetPassword(userParameter).subscribe((data: any) => {
      if (data.StatusDesc == 'SUCCESS') {
        this.toastService.success('Password changed successfully.');
        this.router.navigate(['/']);
      } else {
        this.toastService.error(
          'Unable to change the password. Please try again.'
        );
      }
    });
  }
}
