<p-dialog
  [header]="'Upload File'"
  [(visible)]="showImportModal"
  [modal]="true"
  [breakpoints]="{ '1024px': '75vw', '960px': '75vw', '640px': '90vw' }"
  [style]="{ width: '70vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="hideModal()"
>
  <!-- Modal body -->
  <div class="body-section">
    <form class="form">
      <div class="row">
        <div class="d-flex mb-4">
          <div class="form-group">
            <label>Please upload your files.</label>
            <input
              type="file"
              (change)="selectFiles($event)"
              #myInput
              multiple
              class="form-control"
              id="fileupload"
              name="fileupload"
            />
          </div>
          <button
            pButton
            type="button"
            label="Upload"
            id="button1"
            class="p-button-sm"
            icon="pi pi-upload"
          ></button>
        </div>
        <textarea
          readonly
          type="text"
          class="form-control input-type2"
          id="fileName"
          name="fileName"
          *ngFor="let file of allTypeFiles"
          >{{ file.name }}</textarea
        >
      </div>
    </form>
  </div>

  <!-- Modal footer -->
  <ng-template pTemplate="footer">
    <div class="modal-footer-section">
      <button
        type="button"
        class="btn btn-primary"
        data-dismiss="modal"
        (click)="hideModal('upload')"
      >
        Submit
      </button>
      <button
        type="button"
        class="btn btn-danger"
        data-dismiss="modal"
        (click)="hideModal()"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</p-dialog>
