import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { HttpHelperService } from './http-helper.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  currentUrlSubject = new BehaviorSubject('');
  constructor(
    private httpHelperService: HttpHelperService,
    private apiService: ApiService
  ) {}

  getMenus() {
    const apiUrl = '/api/menus';
    return this.apiService.get(apiUrl, '', false, {});
  }

  getUserMenus(userId: any, roleName: string) {
    const apiUrl = '/api/menus/user?roleName=' + roleName + '&userId=' + userId;
    return this.apiService.get(
      apiUrl,
      '',
      false,
      this.httpHelperService.getTenantHttpOptions()
    );
  }

  getBusinessOptimized(businessId: any) {
    const apiUrl = '/api/v1/businesses/' + businessId;
    return this.apiService.get(
      apiUrl,
      'Unable to load Business Information',
      false,
      this.httpHelperService.getTenantHttpOptions(businessId)
    );
  }

  public getEmailSmsCount() {
    const url = '/api/v1/audit/email-sms/count';
    return this.apiService.get(
      url,
      '',
      false,
      this.httpHelperService.getTenantHttpOptions()
    );
  }
}
