import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import * as $ from 'jquery';
import { Subscription, timer } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  messages: any[] = [];
  userProfile: any;
  firstName: any;
  businessId: any;
  userId: any = sessionStorage.getItem('UserID');
  countDown: Subscription;
  counter = parseInt(sessionStorage.getItem('Time'));

  tick = 1000;
  displayPosition: boolean;
  position: string;

  constructor(private primengConfig: PrimeNGConfig,
    private router: Router) {}
  

  toggleSideBar() {
    $('.main-container').toggleClass('sidebarCollapse');
  }
  ngOnInit() {
    this.countDown = timer(0, this.tick).subscribe(() => --this.counter);

    this.primengConfig.ripple = true;
  }
  ngOnDestroy() {
    this.countDown = null;
  }

  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  }

  logoutBtn() {
    sessionStorage.clear();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }

}

@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {

  
  
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

 
  
}

// import { Component, OnInit } from '@angular/core';
// import * as $ from 'jquery';
// import { Subscription } from 'rxjs';
// import { environment } from 'src/environments/environment';
// import { LocalStorageService } from 'src/app/services/local-storage.service';
// import { AuthService } from 'src/app/guards/auth.service';
// import { CurrentUserService } from 'src/app/services/current-user.service';
// @Component({
//   selector: 'app-header',
//   templateUrl: './header.component.html',
//   styleUrls: ['./header.component.css']
// })
// export class HeaderComponent implements OnInit {
//   messages: any[] = [];
//   userProfile: any;
//   firstName: any;
//   subscription: Subscription;
//   businessId: any;

//   constructor(
//     private localStorageService: LocalStorageService,
//     private authService: AuthService,
//     private currentUserService: CurrentUserService
//   ) {
//     this.subscription = this.authService.getMessage().subscribe((message) => {
//       if (message) {
//         this.userProfile = message.message;
//         console.log('user profile------------', this.userProfile);
//         if (this.userProfile.businessId) {
//           this.businessId = this.userProfile?.businessId;
//         }
//         this.messages.push(message);
//       } else {
//         this.messages = [];
//       }
//     });

//     this.currentUserService.currentUserSubject.subscribe((data: any) => {
//       console.log('isSupport', data);
//       this.businessId = data.businessId;
//     });
//   }

//   toggleSideBar() {
//     $('.main-container').toggleClass('sidebarCollapse');
//   }
//   ngOnInit() {
//     this.loadUserData();
//     if (!this.userProfile) {
//       this.loadUserDataByApi();
//     }
//   }

//   loadUserData() {
//     this.userProfile = this.localStorageService.readStorage('currentUser');
//   }

//   loadUserDataByApi() {
//     this.authService.currentUserSubject.subscribe((data: any) => {
//       console.log(data);
//     });
//   }

//   gotoOldUI() {
//     const dataToSend: any = this.localStorageService.readStorage('currentUser');
//     console.log(dataToSend);
//     // environment.BOOKING_DOMAIN_URL
//     const url: any =
//       // 'http://localhost:4201' +
//       environment.BOOKING_DOMAIN_URL +
//       '/login?token=' +
//       dataToSend?.idToken +
//       '&bid=' +
//       dataToSend?.businessId +
//       '&userId=' +
//       dataToSend.id +
//       '&roles=' +
//       dataToSend.roles +
//       '&profileImageUrl=' +
//       dataToSend?.profileImageUrl;
//     window.open(url, '_self');
//   }
// }
