import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { UploadFiles } from '../modules/loginPage/models/upload-files.model';


@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  currentUserSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    this.currentUserSubject.next(
      this.localStorageService.readStorage('currentUser')
    );
  }
  public get currentUserValue(): UploadFiles {
    return this.currentUserSubject.value;
  }

  logout() {
    this.localStorageService.clearStorage();
    this.currentUserSubject.next(null);
    this.router.navigateByUrl('/');
  }
}
