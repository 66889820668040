<div class="header">
  <p-dialog
    header="Godfather I"
    [(visible)]="displayPosition"
    [position]="position"
    [modal]="true"
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
    [draggable]="false"
    [resizable]="false"
  >
    <ng-template pTemplate="footer">
      <p-button
        icon="pi pi-check"
        (click)="displayPosition = false"
        label="Yes"
        class="p-button-text"
      ></p-button>
      <p-button
        icon="pi pi-times"
        (click)="displayPosition = false"
        label="No"
      ></p-button>
    </ng-template>
  </p-dialog>
  <div class="side-bar-header">
    <img id="logoHeader" src="assets/img/logo.png" alt="" />
  </div>
  <!-- <div class="menu-toggle" (click)="toggleSideBar()">
    <i class="hamburger align-self-center"></i>
  </div> -->
  <div class="breadcrumb-wrapper">
    <div class="breadcrumbs">
      <!-- <h6 style="color: #009ede">Agreement Detail</h6>
      <h6>-> Add AMC</h6> -->
      <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="button-wrapper">
      <!-- <link rel="icon" type="image/x-icon" href="assets/img/logo.png" /> -->
      <div class="profile">
        <div
          class="profile-card"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <ng-container *ngIf="userProfile?.profileImageUrl">
            <img
              [src]="
                userProfile?.profileImageUrl ??
                'https://g99plus.b-cdn.net/AEMR/assets/img/profileDefault.png'
              "
              alt="profile img"
              class="profile-img"
            />
          </ng-container>

          <div class="count-down">
            <p class="name normal" style="color: red">
              {{ counter | formatTime }}
            </p>
          </div>
          &nbsp;&nbsp;&nbsp;

          <div class="link-wrapper" style="margin-right: 12px">
            <div class="right-link">
              <!-- <a href={{quickLinkData?.clinicBookingUrl}} target="_blank">Clinic URL</a> -->
            </div>
          </div>
          &nbsp;&nbsp;&nbsp;
          <ng-container
            *ngIf="!userProfile?.profileImageUrl"
            profileImageUrl
            class="nav-item dropdown"
          >
            <div class="business-container">
              <div class="centered-img">
                <img
                  [src]="'assets/img/profilePicture.jpg'"
                  alt="profile img"
                  class="profile-img"
                />
              </div>

              <div class="business-content">
                <span>Welcome, {{ userId }}</span>
              </div>
            </div>
            <span class="dropdown-menu" aria-labelledby="navbarDropdown">
              <select class="dropdown-item" (click)="logoutBtn()">
                <option value="logout">Logout</option>
              </select>
            </span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
