<p-dialog
  [header]="'Delete ' + modalData?.titleName"
  [(visible)]="showModal"
  [modal]="true"
  [breakpoints]="{ '1024px': '75vw', '960px': '75vw', '640px': '90vw' }"
  [style]="{ width: '70vw' }"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  (onHide)="hideModal()"
>
  <!-- Modal body -->
  <div class="body-section">
    {{ "Are you sure you want to delete " }} <b>{{ modalData?.feildName }}</b>

    <br />
    <br />
    {{ modalMessage }}
  </div>

  <!-- Modal footer -->
  <ng-template pTemplate="footer">
    <div class="modal-footer-section">
      <button
        type="button"
        class="btn btn-primary"
        data-dismiss="modal"
        (click)="hideModal()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger"
        data-dismiss="modal"
        (click)="delete()"
      >
        Delete
      </button>
    </div>
  </ng-template>
</p-dialog>
