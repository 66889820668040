<div class="main-container">
  <div class="authenticated-section">
    <!-- <div class="left-sidebar" *ngIf="sidebar">
      <app-side-bar></app-side-bar>
    </div> -->
    <!-- <div class="right-section shift-right"> -->
    <div class="header-section" *ngIf="navbar">
      <app-header></app-header>
    </div>
    <div class="route-section">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- </div> -->
</div>
<ngx-ui-loader></ngx-ui-loader>
